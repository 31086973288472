import { Typography } from "@mui/material";
import { Button } from "react-admin";
import { useFormContext } from "react-hook-form";

export const CustomClearText = ({ formField, setNotGSMCompliantOnLoad }) => {
    const formContext = useFormContext();

    const handleClick = () => {
        formContext.setValue(formField, '');
        if (setNotGSMCompliantOnLoad) {
            setNotGSMCompliantOnLoad(false);
        }
    };

    return (
        <Button onClick={handleClick}>
            <Typography style={{ fontSize: '16px' }}>CLEAR TEXT</Typography>
        </Button>
    );
}
import { Grid } from '@mui/material';
import {
    AutocompleteInput,
    BooleanInput,
    Button,
    CheckboxGroupInput,
    Edit,
    FormDataConsumer,
    ReferenceInput,
    required,
    SimpleForm,
    useGetOne,
    useNotify,
    useRedirect,
    TextInput,
    useRecordContext,
    SaveButton,
    Toolbar,
    RadioButtonGroupInput
} from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useEffect, useState } from 'react';
import '../../scss/pages/EnhancedMessaging.scss';
import SendTestMessage from '../../components/enhancedmessaging/SendTestMessage';
import SelectionCriteria from './SelectionCriteria';
import RenderMessageTemplate from './RenderMessageTemplate';
import transformAutomationsData from './TransformAutomationsData';
import ScheduleCriteria from './ScheduleCriteria';

const transform = (data: any) => {
    data = {
        ...data,
        practice_id: sessionStorage.getItem('selectedPractice') || '',
        template_id: data.template.id,
        trigger_id: data.trigger?.id,
        is_broadcast: false,
    };

    data = transformAutomationsData(data);

    // delete fields that are not in the model
    delete data.template; // auto populating, only need template_id
    delete data.practice; // auto populating, only need practice_id
    delete data.trigger; // auto populating, only need trigger_id

    return data;
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

/* Start template selection functions */
const FilteredTemplates = ({ event_types }) => {
    if (!event_types) event_types = ['General'];
    const valid_event_types = useWatch().valid_event_types;
    // if valid_event_types is not set or is set and not matching the current event_types, set it to current event_types
    const setValid = (!valid_event_types || (valid_event_types && valid_event_types.join() !== event_types.join()));

    return (<>
        {/* workaround for conditional useFormContext hook call */}
        {setValid && <SetValidEventType event_types={event_types} />}
        <ReferenceInput
            source='template.id'
            reference='messagetemplates'
            filter={{ is_broadcast: false, is_provider_notification: false, event_type: event_types }}
            sort={{ field: 'name', order: 'ASC' }}
        >
            <AutocompleteInput
                optionText='name'
                label='Select a template...'
                filterToQuery={(value) => ({ name: value })}
                validate={[required()]}
            />
        </ReferenceInput>
    </>);
}

const GetTriggerAndFilter = ({triggerId}) => {
    const record = useGetOne('triggers', { id: triggerId }).data;
    let event_filter = ['General'];
    if (record && record.category) event_filter.push(record.category);
    return <FilteredTemplates event_types={event_filter} />;
}

const SetValidEventType = ({ event_types }) => {
    useFormContext().setValue('valid_event_types', event_types);
    return null;
}
/* End template selection functions */

const MessageAutomationEdit = () => {
    const [isBroadcast, setIsBroadcast]= useState(false)
    const redirect = useRedirect();
    const [currentRecord, setCurrentRecord] = useState<string>("")

    useEffect(()=> {
        if (isBroadcast && currentRecord){
            redirect('/messagebroadcasts/' + currentRecord);
        }
    },[isBroadcast, currentRecord, redirect])

    const notify = useNotify();

    const RedirectBroadcasts = () => {
        const record = useRecordContext();
        // function sets state based on current record context for eventual redirects
        
        if (record && record.is_broadcast) {
            // setting both state items to trigger use effect for redirect
            setCurrentRecord(record.id.toString())
            setIsBroadcast(true)
        }
        return null;
    };

    const onSuccess = (data: any) => {
        notify('Automation updated', { type: 'info' });
        redirect('/messageautomations');
    }

    const onCancel = (data: any) => {
        redirect('/messageautomations');
    }

    const CustomToolbar = (props) => {
        return <Toolbar {...props} style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Button
                label='Cancel'
                size='medium'
                variant='contained'
                onClick={onCancel}
            />
            <SaveButton alwaysEnable />
        </Toolbar>
    }

    return (
        <Edit
            className='automation-edit'
            sx={{ maxWidth: '1020px' }}
            transform={transform}
            mutationOptions={{ onSuccess }}
            mutationMode='pessimistic'
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='automation'
                toolbar={<CustomToolbar />}
            >
                <Grid {...gridProps}>
                    <Grid item xs={10}>
                        <h1>Edit Automation</h1>
                    </Grid>
                    <Grid item xs={2} sx={{ marginTop: '28px' }}>
                        <BooleanInput source='enabled' label='Enabled' />
                    </Grid>
                </Grid>
                <Grid {...gridProps}>
                    <p className='automation-label'>1. Name your automation:</p>
                    <Grid item xs={12}>
                        <TextInput source='name' validate={[required()]} fullWidth />
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='automation-label'>2. Select your channels:</p>
                    <Grid item xs={12}>
                        <CheckboxGroupInput
                            source='channels'
                            label=''
                            choices={[
                                { id: 'sms', name: 'Text Message' },
                                { id: 'email', name: 'Electronic Mail' },
                            ]}
                            validate={[required()]}
                            defaultValue={['sms']}
                        />
                    </Grid>
                </Grid>

                <ScheduleCriteria mode='edit' />

                <SelectionCriteria mode='edit' />

                <Grid {...gridProps} className='automation-richtext'>
                    <p className='automation-label'>5. Select your message:</p>
                    <Grid item xs={12}>
                        <FormDataConsumer>
                            {({ formData }) => {
                                if (formData.type === 'event' && formData.trigger && formData.trigger.id) {
                                    return <GetTriggerAndFilter triggerId={formData.trigger.id} />
                                } else if (formData.type === 'appointment') {
                                    return <FilteredTemplates event_types={['General', 'Appointments']} />
                                } else {
                                    return <FilteredTemplates event_types={null} />
                                }
                            }}
                        </FormDataConsumer>
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) => <>
                            {formData.template && formData.template.id && (
                                <Grid item xs={12}>
                                    <RenderMessageTemplate template_id={formData.template.id} />
                                </Grid>
                            )}
                        </>}
                    </FormDataConsumer>
                </Grid>
                <SendTestMessage origin='automation' />
                <RedirectBroadcasts/>
            </SimpleForm>
        </Edit>
    );
};

export default MessageAutomationEdit;

import { useEffect, useState } from "react";
import {Button as MUIButton } from '@mui/material';

import {
    Button,
    Confirm,
    DateInput,
    fetchUtils,
    SaveButton,
    TimeInput,
    Toolbar,
} from "react-admin";
import SendIcon from '@mui/icons-material/Send';
import ScheduleIcon from '@mui/icons-material/Schedule';
import SendTestMessage from "./SendTestMessage";
import TransformBroadcastData from "./TransformBroadcastData";
import TimeZoneNotice from "./TimeZoneNotice";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import tokenManager from "../../tokenManager";


const fetchCreditUsage = async (practiceId: string) => {

    const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
    const accessToken = tokenManager.getToken();

    if (!practiceId) {
        console.error("Practice ID is missing");
        return;
    }

    const options: any = {}
    options['credentials'] = 'include'
    options['user'] = {
        authenticated: true,
        token: `Bearer ${accessToken}`,
    }


    const url = `${apiUrl}/kaizenovate/provider/1.0.0/get-sms-credit-usage?practice_id=${practiceId}`;

    const result = await fetchUtils.fetchJson(url, options)
    // console.log(result);


    if (result.status < 200 || result.status >= 300) {
        console.error("An error occurred while trying to sync patient DX codes")
    }

    return result.json;


}

const ConfirmSMSCredits = ({ setScheduleOpen, sendNowPressed, setSendNowPressed, setCreditNotify, credits, estimatedAudience, estimatedCredits, estimatedCreditsRange,  }) => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    useEffect(() => {
        if (!hasOpened) {
            setOpen(true);
            setHasOpened(true);
        }
        
    }, [hasOpened]);

    // console.log("estimatedCreditsRange", estimatedCreditsRange)

    let minCredits;
    let maxCredits;

    if (estimatedCreditsRange.includes("-")) {
        let estimatedCreditsArray = estimatedCreditsRange.split("-")
        minCredits = parseInt(estimatedCreditsArray[0])
        maxCredits = parseInt(estimatedCreditsArray[1])
    }
    // for testing
    // if (estimatedAudience < 1) {
    //     estimatedAudience = 1;
    // }

    let minBroadCastUsage = (parseInt(minCredits) * parseInt(estimatedAudience));
    let maxBroadCastUsage = (parseInt(maxCredits) * parseInt(estimatedAudience));

    let estimatedUsage = minCredits && maxCredits > 1 ?  `${minBroadCastUsage}-${maxBroadCastUsage}` : (estimatedAudience * estimatedCredits);
    let overageRate = .02
    let estimatedUsageAfterMessage = (credits.usedCredits + estimatedUsage) // this is adding a string to a number currently need to fix for separate minCredits maxCredits average credits stuff
    // credits.usedCredits = 1234; // for testing 
    let estUsageAfterRange = `${credits.usedCredits + minBroadCastUsage}-${credits.usedCredits + maxBroadCastUsage}`;
    
    // console.log("estimatedUsage", estimatedUsage)
    let warningStyle = { color: estimatedCredits > 1 || estimatedCreditsRange.length > 1 || parseInt(estimatedCreditsRange) > 1 || estimatedCredits !== 1 ? "red" : "inherit" }

    let estimatedCreditsOverage = (credits.usedCredits + estimatedUsage) - credits.planCredits ;
    let estimatedCreditsOverageDollar = (estimatedCreditsOverage * overageRate).toLocaleString("en-US", { style: "currency", currency: "USD" });

    let overageRangeMin = (parseInt(credits.usedCredits) + minBroadCastUsage) - parseInt(credits.planCredits);
    let overageRangeMax = (parseInt(credits.usedCredits) + maxBroadCastUsage) - parseInt(credits.planCredits);
    // console.log("overageRangeMin", overageRangeMin)

    let overageRangeMinDollar = ((overageRangeMin) * overageRate).toLocaleString("en-US", { style: "currency", currency: "USD" });
    let oveageRangeMaxDollar = ((overageRangeMax) * overageRate).toLocaleString("en-US", { style: "currency", currency: "USD" });

    // console.log("estUsageAfterRange", estUsageAfterRange)

    // console.log("estimatedCreditsOverage", estimatedCreditsOverage)

    return (
        <Dialog
        sx={{
            "& .MuiDialog-paper": {
              maxWidth: "480px", 
              width: "100%",
              margin: "25px",
            //   height: "250px",
            //   minHeight: "250px"
            },
          }}
            open={open}
            onClose={() => {
                setCreditNotify(false);
                setOpen(false);
                setSendNowPressed(false);
                setScheduleOpen(false);
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"Authorize SMS Credits"}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Your plan includes {credits.planCredits} SMS credits per month.
                    </DialogContentText>
                    <p>Monthly Credits used so far: {credits.usedCredits} / {credits.planCredits}</p>
                    <div>This Broadcast Credit Usage</div>
                    <div style={{ color: estimatedAudience > 100 || estimatedAudience === 0 ? "red" : "inherit" }}>
                        Estimated number of messages: {estimatedAudience}
                    </div>
                    <div>Estimated credits per message: <span style={warningStyle}> {estimatedCreditsRange ? estimatedCreditsRange : estimatedCredits}</span></div>
                    <div>Estimated total credits for broadcast: <span style={warningStyle}> {estimatedUsage}</span> </div>
                    {isNaN(overageRangeMin) ? <p>
                        Estimated total credits after message: <span style={{ color: estimatedUsageAfterMessage > credits.planCredits ? "red" : "inherit" }}>{estimatedUsageAfterMessage} / {credits.planCredits}</span>
                    </p> : null}
                    {!isNaN(overageRangeMin) ? <p>
                        Estimated total credits after message: <span style={{ color: (credits.usedCredits + maxBroadCastUsage) > credits.planCredits ? "red" : "inherit" }}>{estUsageAfterRange} / {credits.planCredits}</span>
                    </p> : null}
                    {estimatedUsageAfterMessage > credits.planCredits ? <p style={{ color: "red" }}>We estimate sending this broadcast will cause you to exceed your monthly SMS credit allocation by <strong>{ estimatedCreditsOverage} SMS Credits which will incure a cost of {estimatedCreditsOverageDollar} </strong> </p> : null}
                    {(credits.usedCredits + maxBroadCastUsage) > credits.planCredits ? <p style={{ color: "red" }}>We estimate sending this broadcast will cause you to exceed your monthly SMS credit allocation by <strong>{overageRangeMin > 0 ? `${overageRangeMin}-${overageRangeMax}`: `by a possible ${overageRangeMax}` } SMS Credits which will incure a cost of {overageRangeMin > 0 ?`${overageRangeMinDollar}-${oveageRangeMaxDollar}` :`${oveageRangeMaxDollar}` }</strong> </p> : null}
                    
                <DialogContentText id="alert-dialog-description">

                    <strong>
                        Note: If you exceed {credits.planCredits} SMS credits in a given month, you will be charged {overageRate} per additional SMS credit. Both automations and broadcasts are included in your monthly SMS credit allocation.
                    </strong>
                    
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between', margin: '5px 14px 15px 14px' }}>
                <MUIButton onClick={() => {
                    setCreditNotify(false);
                    setOpen(false);
                    setSendNowPressed(false);
                    setScheduleOpen(false);
                }} variant="contained" sx={{
                    display: "flex", // Ensures the button is a flex container
                    alignItems: "center", // Vertically centers content
                    justifyContent: "center", // Horizontally centers content
                    textTransform: "none", // Prevents uppercase transformation
                    height: "50px", // Adjust height if needed
                    width: "150px", // Adjust width if needed
                    backgroundColor: '#707075',
                    color: 'white',
                }}>
                    <Typography >Cancel</Typography>
                </MUIButton>
                <MUIButton variant="contained" onClick={() => {
                    if (sendNowPressed) {
                        // send message now
                        const submitBtn = document.querySelector('.broadcase-send-submit') as HTMLElement | null;
                        if (submitBtn) {
                            submitBtn.click();
                        }
                    }
                    setCreditNotify(false);
                    setSendNowPressed(false);
                    setOpen(false);
                }} color="primary" autoFocus sx={{
                    display: "flex", // Ensures the button is a flex container
                    alignItems: "center", // Vertically centers content
                    justifyContent: "center", // Horizontally centers content
                    textTransform: "none", // Prevents uppercase transformation
                    height: "50px", // Adjust height if needed
                    width: "150px", // Adjust width if needed
                }}>
                    <Typography>Confirm</Typography>
                </MUIButton>
            </DialogActions>
        </Dialog>
    );
}

const ScheduleOrSendToolbar = ({ estimatedAudience, estimatedCredits, estimatedCreditsRange, templateContent  }) => {
    const [open, setOpen] = useState(false);
    const [creditNotify, setCreditNotify] = useState(false);
    const [sendNowPressed, setSendNowPressed] = useState(false);
    const [credits, setCredits] = useState({ planCredits: 0, usedCredits: 0 });

    useEffect(() => {
        const practiceId = sessionStorage.getItem("selectedPractice"); // Replace "default-practice-id" with an appropriate fallback if needed
        if (practiceId) {
            fetchCreditUsage(practiceId).then((data) => {
                // console.log("Fetched credit usage data:", data);
                if (data) {
                    setCredits({
                        planCredits: data.credit_limit,
                        usedCredits: data.credits_used,
                    });
                }
            }).catch((error) => {
                console.error("Error fetching credit usage:", error);
            });
        }
    }, []);

    return (
        <Toolbar className='broadcast-save-toolbar'>
            {creditNotify ? <ConfirmSMSCredits
                setScheduleOpen={setOpen}
                setSendNowPressed={setSendNowPressed}
                sendNowPressed={sendNowPressed}
                setCreditNotify={setCreditNotify}
                credits={credits}
                estimatedAudience={estimatedAudience}
                estimatedCredits={estimatedCredits}
                estimatedCreditsRange={estimatedCreditsRange}
               />
                : null}
            {/* trigger schedule window */}
            <Button
                label='Schedule message'
                onClick={() => {

                    setCreditNotify(true)
                    setOpen(true)
                }}
                startIcon={<ScheduleIcon />}
                variant='contained'
                className='broadcast-schedule-trigger'
            />
            <Confirm
                isOpen={open}
                title='Schedule Message'
                className='broadcast-schedule-modal'
                content={<>
                    <div className='broadcast-schedule-fields'>
                        <DateInput source='date' />
                        <TimeInput source='time' />
                        {/* <SelectInput source='time_zone'
                            validate={[required()]} // this + defaultValue remove the empty option
                            defaultValue='New_York' // update to practice timezone
                            choices={[
                                { id: 'New_York', name: 'New York (EDT)' },
                                { id: 'Chicago', name: 'Chicago (CDT)' },
                                { id: 'Denver', name: 'Denver (MDT)' },
                                { id: 'Phoenix', name: 'Phoenix (MST)' },
                                { id: 'Los_Angeles', name: 'Los Angeles (PDT)' },
                                { id: 'Anchorage', name: 'Anchorage (AKDT)' },
                                { id: 'Honolulu', name: 'Honolulu (HST)' },
                            ]} 
                        /> */}
                    </div>
                    <div style={{ width: '48%', paddingLeft: '52%', marginTop: '-20px' }}>
                        <TimeZoneNotice />
                    </div>
                </>}
                onClose={() => {
                    setCreditNotify(false)
                    setOpen(false)
                }}
                onConfirm={() => {
                    sessionStorage.setItem("scheduled", "true")
                    const submitBtn = document.querySelector('.broadcast-schedule-submit') as HTMLElement
                    setOpen(false)
                    submitBtn.click()
                }}
                confirm='Send'
            />

            {/* hidden submit for schedule */}
            <SaveButton className='broadcast-schedule-submit' label='Schedule message' />

            {/* send message now */}
            <SaveButton
                className='broadcase-send-submit'
                sx={{ display: 'none' }}
                label='Send message now'
                icon={<SendIcon />}
                type='button'
                transform={(data: any) => TransformBroadcastData(data, true)}
            />
            <Button
                label='Send message now'
                startIcon={<SendIcon />}
                variant='contained'
                className='broadcast-schedule-trigger'
                onClick={() => {
                    sessionStorage.setItem("scheduled", "false")

                    setCreditNotify(true)
                    setSendNowPressed(true)
                }}
            />
            <SendTestMessage origin='broadcast' />
        </Toolbar>
    )
}

export default ScheduleOrSendToolbar;
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button as MUIButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useWatch } from "react-hook-form";

export const ConfirmMultiCredit = ({ estimatedCredits, estimatedCreditsRange, setMultiCreditNotify }) => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);
    // remove special characters 
    const formValues = useWatch();
    // const channels = formValues.channels;

    useEffect(() => {
        if (!hasOpened) {
            // console.log("has not opened");
            setOpen(true);
            setHasOpened(true);
            // console.log("formValues.sms_message", formValues.sms_message);
        } else {
            // console.log("has opened");
        }
    }, [hasOpened]);

    const onCancel = () => {
        // setHasOpened(true);
        setOpen(false)
        setMultiCreditNotify(false);
        // console.log("on cancel clicked")
    }
    
    const onConfirm = () => {
        setOpen(false)
        setHasOpened(true);
        // console.log("on confirm clicked")
        const submitBtn = document.querySelector('.template-save-submit') as HTMLElement
        // console.log("submitBtn", submitBtn)
        submitBtn.click()
    }

    return (
        <Dialog open={open} onClose={onCancel} sx={{
            "& .MuiDialog-paper": {
              maxWidth: "465px", 
              width: "100%",
              margin: "25px",
            //   height: "250px",
            //   minHeight: "250px"
            },
          }}>
            <DialogTitle style={{  fontWeight: 'bold' }}>Multi-Credit Template</DialogTitle>
            <DialogContent >
                <p>
                    Based on your text message content, we expect this message to exceed one credit for each message.  Are you sure you would like to spend an estimated {estimatedCreditsRange ? estimatedCreditsRange : estimatedCredits} credits per message?
                </p>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'space-between', margin: '0px 15px 12px 15px' }}>
                <MUIButton variant="contained" size='medium' onClick={onCancel} color='error'
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textTransform: "none",
                        height: "50px",
                        whiteSpace: "nowrap",
                        padding: "0 20px",
                    }}>
                    <Typography>Back to Template</Typography>
                </MUIButton>
                <MUIButton variant="contained" size='medium' onClick={onConfirm} color="primary" autoFocus 
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "none",
                    height: "50px",
                    width: "150px",
                }}>
                    <Typography>Confirm</Typography>
                </MUIButton>
            </DialogActions>
        </Dialog>
    );
}
import { Grid, Typography } from '@mui/material';
import {
    AutocompleteInput,
    AutocompleteArrayInput,
    BooleanInput,
    CheckboxGroupInput,
    Create,
    FormDataConsumer,
    RadioButtonGroupInput,
    ReferenceArrayInput,
    ReferenceInput,
    required,
    SimpleForm,
    useGetOne,
    useNotify,
    useRedirect,
    Confirm,
    useRefresh,
    useRecordContext,
    TextInput,
    fetchUtils,
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { useWatch, useFormContext } from 'react-hook-form';
import '../../scss/pages/EnhancedMessaging.scss';
import QueryBuilder from '../../components/enhancedmessaging/QueryBuilder';
import CustomRichTextToolbar from '../../components/enhancedmessaging/CustomRichTextToolbar';
import ScheduleOrSendToolbar from '../../components/enhancedmessaging/ScheduleOrSendToolbar';
import TransformBroadcastData from '../../components/enhancedmessaging/TransformBroadcastData';
import { useEffect, useState } from 'react';
import { InfoOutlined } from '@mui/icons-material';
import tokenManager from "../../tokenManager";
import { dynamicTextRanges } from '../../segments/dynamicTextRanges';
import { HtmlTooltip } from '../../components/smstransparency/HtmlTooltip';
import { ConfirmSpecialText } from '../../components/smstransparency/ConfirmSpecialText';
import { isGSM7Compliant } from '../../components/smstransparency/gsm7ComplianceHelpers';
import { CustomClearText } from '../../components/smstransparency/CustomClearText';

const transform = (data: any, sendNow: boolean) => {
    // default submit transform - not in use due to ScheduleOrSendToolbar override
    const transformResult = TransformBroadcastData(data, sendNow);
    return transformResult
}

const gridProps = {
    container: true,
    rowSpacing: 1,
    mb: 1,
    columnSpacing: 1
}

const RenderMessageTemplate = ({setTemplateContent}) => {
    const formValues = useWatch();
    const channels = formValues.channels;
    // RenderMessageTemplate is only called when formValues.template exists
    const record = useGetOne(`messagetemplates`, { id: formValues.template });

    const formatMessage = (message: string) => {
        // repalce newlines with <p> tags
        let sms = message ? message.replaceAll('\n', '</p><p>') : '';
        if (!sms.startsWith('<p>') && !sms.endsWith('</p>')) sms = '<p>' + sms + '</p>';
        // no more than one consecutive empty paragraph
        sms = sms.replace(/(<p><\/p>){2,}/g, '<p></p>');
        setTemplateContent(sms)
        return sms;
    }

    if (channels && record.data) {
        const sms_html = channels.includes('sms') && record.data.sms_message && (
            <>
                <p>SMS template</p>
                <div
                    className='broadcast-textarea'
                    dangerouslySetInnerHTML={{ __html: formatMessage(record.data.sms_message) }}
                />
            </>
        );

        const email_html = channels.includes("email") &&
            record.data.email_message && record.data.name ? (
            <>
                <p >Email Subject</p>
                <div
                    id="email-subject"
                    className="broadcast-textarea"
                    style={{ minHeight: "35px" }}
                    dangerouslySetInnerHTML={{
                        __html: formatMessage(record.data.email_subject || record.data.name),// prefers email_subject over name of template
                    }}
                />

                <p>Email template</p>
                <div
                    className="broadcast-textarea"
                    dangerouslySetInnerHTML={{
                        __html: formatMessage(record.data.email_message),
                    }}
                />
            </>
        ) : null;
        return (
            <div className="broadcast-textarea-wrapper">
                {sms_html}
                {email_html}
            </div>
        );
    }
    return <div></div>;
};

const ConfirmElectronicMail = () => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const formValues = useWatch();
    const channels = formValues.channels;

    if (channels && channels.includes('email') && !hasOpened) {
        setOpen(true);
        setHasOpened(true);
    }

    return (
        <Confirm
            isOpen={open}
            title='Electronic Mail'
            className='broadcast-confirm-modal'
            content={
                <p>
                    Because you have enabled email for this message, we have added formatting options, but keep in mind formatting is not available for Text Messages and App Notifications.
                </p>
            }
            onClose={() => setOpen(false)}
            onConfirm={() => setOpen(false)}
            confirm='Continue'
        />
    );
};


const validateAdvancedQuery = (advancedQuery: any) => {
    if (!advancedQuery) return 'Required';
    try { // if valid JSON string
        if (advancedQuery) {
            const query = JSON.parse(advancedQuery);
            return validateQuery(query); // validate if it has a rule besides the default one
        } else {
            return 'Invalid JSON';
        }
    } catch (e) {
        return 'Invalid JSON';
    }
}

const validateQuery = (query: any) => {
    if (query && query.rules && query.rules.length > 0) {
        // invalid if the only rule is the default rule
        const rules = query.rules.filter((rule: any) => rule.field !== 'default');
        if (rules.length > 0) {
            const ruleEmptyValue = rules.filter((rule: any) => !rule.value);
            if (ruleEmptyValue.length > 0) return 'Rule value is required';
            else return undefined;
        } else {
            return 'Query needs at least one rule';
        }
    } else {
        return 'Query needs at least one rule';
    }
}

// ConfirmAdvancedQuery can't be conditionally rendered because it would reset 'hasOpened'
const ConfirmAdvancedQuery = () => {
    const [open, setOpen] = useState(false);
    const [hasOpened, setHasOpened] = useState(false);

    const formValues = useWatch();
    const formContext = useFormContext();
    const advancedMode = formValues.advanced_query_enabled;

    if (advancedMode && !hasOpened) {
        setOpen(true);
        setHasOpened(true);
    }

    let query = formValues.selection_criteria;

    if (advancedMode) {
        if (query && typeof query === 'object') {
            if (formValues.selection_criteria_with_error) {
                formContext.setValue('selection_criteria', formValues.selection_criteria_with_error)
                formContext.setValue('selection_criteria_with_error', undefined);
            } else {
                query = JSON.stringify(query, null, 4);
                formContext.setValue('selection_criteria', query)
            }
        }
    } else {
        if (query && typeof query === 'string') {
            try {
                query = JSON.parse(query);
                formContext.setValue('selection_criteria', query)
            } catch (e) {
                console.error('Invalid JSON');
                if (!formValues.selection_criteria_with_error) {
                    // set a "backup" of the invalid JSON so the user can edit it
                    formContext.setValue('selection_criteria_with_error', query);
                }
            }
        }
    }

    return (
        <Confirm
            isOpen={open}
            title='Warning! Advanced Feature!'
            className='broadcast-confirm-modal confirm-query'
            content={<>
                <p>
                    Selections entered here may not work as expected. Use only if you are familiar with the JSON format and the query structure.
                </p>
                <p>
                    If you undo this selection and edit the basic criteria, the advanced criteria will be lost.
                </p>
            </>}
            onClose={() => {
                formContext.setValue('advanced_query_enabled', false);
                setOpen(false)
            }}
            onConfirm={() => setOpen(false)}
            confirm='Continue'
        />
    );
};

const creditProcessing = (  formData, specialTextNotify, setSpecialTextNotify, estimatedCredits, setEstimatedCredits, estimatedCreditsRange, setEstimatedCreditsRange, templateContent )=>{

    const htmlDecode = (input) => {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    // handles the case where the user selected a template and then unchecked the use_template checkbox, also if they unselect the template
    if ((!formData.use_template && templateContent) || !formData.template) {
        templateContent = undefined
    }
    let text = templateContent? templateContent : formData.custom_message

    let gsm7compliant = true;
    if (text) {
        gsm7compliant = isGSM7Compliant(text.replace(/^<p>|<\/p>$/gm, '')) // need to remove html tags 
    }

    // needs to be the full dynamic text options if a template that is non general is used

    // check and handle message length

    // get the dynamic text keys
    const dynamicTextKeys = Object.keys(dynamicTextRanges);
    
    const dynamicTextUsage = dynamicTextKeys.reduce((acc, option) => {
        const count = (text?.match(new RegExp(option, 'g')) || []).length;
        if (count > 0) {
            acc[option] = count;
        }
        return acc;
    }, {});

    let estimationLengthMin = 0;
    let estimationLengthMax = 0;
    // let estimationLengthAvg = 0;

    Object.keys(dynamicTextUsage).forEach(key => {
        const count = dynamicTextUsage[key];
        const minLength = dynamicTextRanges[key]?.min || 0;
        const maxLength = dynamicTextRanges[key]?.max || 0;
        // const avgLength = dynamicTextRanges[key]?.average || 0;
        estimationLengthMin += count * minLength;
        estimationLengthMax += count * maxLength;
        // estimationLengthAvg += count * avgLength;

    });

    // console.log(`Min Length: ${estimationLengthMin}, Max Length: ${estimationLengthMax}`);
    let parsedMessage = text?.replace(/<p>/gm, '') // removes html tags
    parsedMessage = parsedMessage?.replace(/<\/p>/gm, '\n') // removes html tags

    // console.log(emojiCount)
    const messageLength = text?.length > 0 ? (Math.abs(Array.from(htmlDecode(parsedMessage)!).length - 1)) : 0;
    // const messageLength = text?.replace(/^<p>|<\/p>$/gm, '')?.length ?? 0;
    // console.log(text)
    const newEstimatedCredits = Math.ceil(messageLength / (gsm7compliant ? 160 : 70));
    if (newEstimatedCredits !== estimatedCredits) {
        setEstimatedCredits(newEstimatedCredits);
    }
    // check and handle dynamic text
    let hasDynamicText = false
    hasDynamicText = dynamicTextKeys.some(option => text?.includes(option));
    if (!gsm7compliant && !specialTextNotify) {
        setSpecialTextNotify(true);
    } else if (gsm7compliant && specialTextNotify) {
        setSpecialTextNotify(false);
    }

    let warningStyle = { color: ((estimatedCredits > 1 || estimatedCreditsRange.length > 1 || (parseInt(estimatedCreditsRange) > 1))) ? "red" : "inherit" }
    // console.log("estimatedCredits", estimatedCredits, "estimatedCreditsRange", estimatedCreditsRange)

    const dynamicTextRegex = new RegExp(dynamicTextKeys.join('|'), 'g');
    const displayMin = parsedMessage?.length ? (Math.abs(Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - 1) + estimationLengthMin) : 0;
    const displayMax = parsedMessage?.length ? (Math.abs(Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - 1) + estimationLengthMax) : 0;
    // const displayAvg = parsedMessage?.length ? (Math.abs(Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - 1) + estimationLengthAvg) : 0;

    if (hasDynamicText) {
        let minCalc = Math.ceil(displayMin / (gsm7compliant ? 160 : 70))
        let maxCalc = Math.ceil(displayMax / (gsm7compliant ? 160 : 70))
        // let avgCalc = Math.ceil(displayAvg / (gsm7compliant ? 160 : 70))
        // console.log("minCalc", minCalc)
        // console.log("maxCalc", maxCalc)
        if (minCalc !== maxCalc) {
            // console.log("minCalc", minCalc)
            // console.log("maxCalc", maxCalc)
            setEstimatedCreditsRange(`${minCalc}-${maxCalc}`);
        } else {
            // console.log("minCalc", maxCalc)
            setEstimatedCreditsRange(`${maxCalc}`);
        }

        // if (avgCalc) {
        //     setAverageCredits(avgCalc);
        // }
    }

    if (!hasDynamicText) {
        setEstimatedCreditsRange("");
    }

    // bring closer to text area 
    let smsDisplayField = <span style={{ fontSize: "14px", ...warningStyle }}>{`${parsedMessage?.length
        ? (hasDynamicText ?
            `${displayMin}-${displayMax}`
            : Math.abs(Array.from(htmlDecode(parsedMessage)!).length - 1))
        : 0} Characters ${hasDynamicText ? "Estimated" : ""} ${gsm7compliant ? "(Basic Text Mode)" : "(Special Character Mode)"} | ${hasDynamicText ? estimatedCreditsRange : estimatedCredits} ${estimatedCredits === 1 && ((estimatedCreditsRange.length === 1 && parseInt(estimatedCreditsRange) <= 1) || estimatedCreditsRange === "") ? "credit" : "credits"} per message ${hasDynamicText ? "estimated " : ""}`}</span>

        return {smsDisplayField, hasDynamicText, messageLength}

}

const MessageBroadcastCreate = () => {
    const [specialTextNotify, setSpecialTextNotify] = useState(false);
    const [estimatedCredits, setEstimatedCredits] = useState(1);
    const [estimatedCreditsRange, setEstimatedCreditsRange] = useState("");
    // const [averageCredits, setAverageCredits] = useState(1);
    const [practiceId] = useState("");
    const [selectionCriteria, setSelectionCriteria] = useState({}); // will be json need to convert pre fetch
    const [advancedQuerySelected, setAdvancedQuerySelected] = useState(false);
    const [templateContent, setTemplateContent] = useState("")
    const [criteriaQueryChosen, setCriteriaQueryChosen] = useState(false);
    const [estimatedPatients, setEstimatedPatients] = useState(0);
    sessionStorage.setItem("scheduled", "false");
    const notify = useNotify();
    const redirect = useRedirect();
    const refresh = useRefresh();

    // structure for fetching patient estimates from the backend
    const fetchPatientEstimates = async (practiceId, selectionCriteriaJSON, advancedQuerySelected) => {
        // console.log("fetchPatientEstimates", selectionCriteriaJSON.rules)

        const rules = selectionCriteriaJSON.rules?.filter((rule: any) => rule.field !== 'default');

        // console.log("CHECKING QUERY", rules)

        if(rules?.length === 0 ){
            setEstimatedPatients(0)
            return
        }

        // pull in validate advanced query and return early if advanced json is invalid
        if (selectionCriteriaJSON && advancedQuerySelected) {
            let isValid = validateAdvancedQuery(selectionCriteriaJSON)
            // if undefined its valid
            if (isValid !== undefined) {
                return;
            }
        }


        const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
        const accessToken = tokenManager.getToken();

        if (!practiceId) {
            console.error("Practice ID is missing");
            return;
        }

        const options = {}
        options['credentials'] = 'include'
        options['user'] = {
            authenticated: true,
            token: `Bearer ${accessToken}`,
        }

        const selectionCriteriaJSONEncode = encodeURIComponent(JSON.stringify(selectionCriteria));
        // console.log(selectionCriteriaJSON);
        const url = `${apiUrl}/kaizenovate/communication/1.0.0/get-patient-estimate/${practiceId}/${selectionCriteriaJSONEncode}`;

        const result = await fetchUtils.fetchJson(url, options)
        // console.log(result);

        if (result.status < 200 || result.status >= 300) {
            console.error("An error occurred while trying to sync patient DX codes")
        }

        if (result && result.json.hasOwnProperty('numberOfPatients')) {
            setEstimatedPatients(result.json.numberOfPatients);
        }
    }

    // code trigger fetchPatientEstimates
    useEffect(() => {
        const practiceId = sessionStorage.getItem("selectedPractice");

        const debounceFetch = setTimeout(() => {
            if (criteriaQueryChosen && practiceId && selectionCriteria) {
                fetchPatientEstimates(practiceId, selectionCriteria, advancedQuerySelected);
            }
        }, 1500); // 1.5 seconds of state changes, you can change state multiple times before timeout and it will only trigger once

        return () => clearTimeout(debounceFetch);
    }, [criteriaQueryChosen, practiceId, selectionCriteria, advancedQuerySelected]);

    const onSuccess = (data: any) => {
        if (sessionStorage.getItem('scheduled') === 'false') {
            notify('Message sent', { type: 'info' });
            redirect('/messagelogs');
        } else {
            notify('Message scheduled', { type: 'info' });
            redirect('/messageautomations');
        }
        setTimeout(refresh, 1000);
    }

    const CustomOption = () => {
        let item = useRecordContext()

        return <span>{item.first} {item.last} ({item.emr_patient_id})</span>
    }

    const EstimatedAudienceSize = ({ estimatedPatients }) => {

        const estimatedAudienceMessage = `Estimated Audience Size:  ${estimatedPatients}`;

        return <div style={{ marginTop: "20px", color: estimatedPatients > 100 || estimatedPatients === 0 ? 'red' : "inherit" }}>{estimatedAudienceMessage} {estimatedPatients === 0 ? (<span> <a href='https://kaizenovate.zendesk.com/hc/en-us' target='_blank'>Support Article</a></span>) : ""}</div>;

    }

    return (
        <Create
            className='broadcast-create'
            sx={{ maxWidth: '1020px' }}
            transform={(data: any) => transform(data, false)}
            mutationOptions={{ onSuccess }}
        >
            <SimpleForm
                sx={{ pt: 0, pb: 0 }}
                className='broadcast'
                // some hardcoded values for now
                toolbar={<ScheduleOrSendToolbar estimatedAudience={estimatedPatients} estimatedCredits={estimatedCredits} estimatedCreditsRange={estimatedCreditsRange} templateContent={templateContent} />}
            >
                <h1>Broadcast Messaging</h1>
                <Grid {...gridProps}>
                    <p className='broadcast-label'>1. Select which patients:</p>
                    <Grid item xs={12}>
                        <RadioButtonGroupInput
                            className='broadcast-radio-group'
                            source='group_type'
                            defaultValue='user_ids'
                            choices={[
                                { id: 'user_ids', name: 'Send to specific patients only' },
                                {
                                    id: 'custom_query',
                                    name: 'Send to all patients that match a criteria',
                                },
                            ]}
                            onChange={(e) => {
                                if (e.target.value === 'custom_query') {
                                    setCriteriaQueryChosen(true);
                                } else {
                                    setCriteriaQueryChosen(false);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ConfirmAdvancedQuery />
                        <FormDataConsumer>
                            {({ formData }) => {
                                // console.log("formData", formData)
                                if ( formData.advanced_query_enabled) {
                                    setAdvancedQuerySelected(true);
                                } else {
                                    setAdvancedQuerySelected(false);
                                }
                                if (formData.selection_criteria){

                                    setSelectionCriteria(formData.selection_criteria)
                                }

                                if (formData?.group_type === 'user_ids') {
                                    if (formData?.user_ids?.length > 0) {
                                        setEstimatedPatients(formData.user_ids.length);
                                    }
                                    return (
                                        <ReferenceArrayInput
                                            source='user_ids'
                                            reference='users'
                                            enableGetChoices={(q) => {
                                                if (q && q.name && q.name.length >= 3) {
                                                    return true;
                                                } else {
                                                    return false;
                                                }
                                            }}
                                        >
                                            <AutocompleteArrayInput className='hello'
                                                // this is what goes into the input array after selection
                                                inputText={(choice) => `${choice.first} ${choice.last}`}
                                                // needs to be a custom component for the drop down items
                                                optionText={<CustomOption />}
                                                filterToQuery={(value) => ({ name: value })}
                                                label='Select users...'
                                                validate={[required()]}
                                            />
                                        </ReferenceArrayInput>
                                    );
                                } else {
                                    return (
                                        <div className='queryBuilder-wrapper'>
                                            <BooleanInput
                                                source='advanced_query_enabled'
                                                label='Advanced input'
                                                sx={{ '& label': { marginLeft: 'auto', marginTop: '-54px', marginBottom: '8px' }, '& p': { display: 'none' } }}
                                            />
                                            {formData.advanced_query_enabled ? (
                                                <TextInput
                                                    source='selection_criteria'
                                                    fullWidth
                                                    multiline
                                                    className='textinput-multiline'
                                                    validate={() => validateAdvancedQuery(formData.selection_criteria)}
                                                />
                                            ) : (<>
                                                <QueryBuilder />
                                                <EstimatedAudienceSize estimatedPatients={estimatedPatients} />
                                                <TextInput
                                                    source='selection_criteria'
                                                    validate={() => validateQuery(formData.selection_criteria)}
                                                    // hide everything but the error message
                                                    sx={{ marginTop: '0', '& label': { display: 'none' }, '& div': { display: 'none' } }}
                                                    // set to QueryBuilder default value in the string format we use for selection_criteria
                                                    defaultValue={
                                                        JSON.stringify({
                                                            combinator: 'and',
                                                            rules: [{
                                                                field: 'default',
                                                                id: '99999999-9999-9999-9999-999999999999',
                                                            }],
                                                        }, null, 4)
                                                    }
                                                />
                                            </>)}
                                        </div>
                                    );
                                }
                            }}
                        </FormDataConsumer>
                    </Grid>
                </Grid>

                <Grid {...gridProps}>
                    <p className='broadcast-label'>2. Select your channels:</p>
                    <Grid item xs={12}>
                        <CheckboxGroupInput
                            source='channels'
                            choices={[
                                { id: 'sms', name: 'Text Message' },
                                { id: 'email', name: 'Electronic Mail' },
                            ]}
                            defaultValue={['sms']}
                            validate={[required()]}
                        />
                        <ConfirmElectronicMail />
                    </Grid>
                </Grid>

                <Grid {...gridProps} className='broadcast-richtext'>
                    <p className='broadcast-label'>3. Select your message:</p>
                    <Grid item xs={12}>
                        <BooleanInput label='Use existing template' source='use_template' />
                    </Grid>
                    <FormDataConsumer>
                        {({ formData }) => {
                            // just need to handle state processing found inside this function
                            creditProcessing(formData, specialTextNotify, setSpecialTextNotify, estimatedCredits, setEstimatedCredits, estimatedCreditsRange, setEstimatedCreditsRange, templateContent)
                            return formData.use_template ? (
                                <>
                                    <Grid item xs={12}>
                                        <ReferenceInput
                                            source='template'
                                            reference='messagetemplates'
                                            filter={{ is_broadcast: false, is_provider_notification: false, event_type: 'General' }}
                                            sort={{ field: 'name', order: 'ASC' }}
                                        >
                                            <AutocompleteInput
                                                optionText='name'
                                                label='Select a template...'
                                                filterToQuery={(value) => ({ name: value })}
                                                validate={[required()]}
                                            />
                                        </ReferenceInput>
                                    </Grid>
                                    {formData.template && (
                                        <Grid item xs={12} className='broadcast-multiline'>
                                            <RenderMessageTemplate setTemplateContent={setTemplateContent}/>
                                        </Grid>
                                    )}
                                </>
                            ) : (
                                <>
                                    {formData.channels && formData.channels.length > 0 && (
                                        <>
                                            {formData.channels.includes('email') ? <Grid item xs={12}>
                                                <p >Email Subject</p>
                                                <RichTextInput
                                                    id="email-subject"
                                                    source="email_subject"
                                                    fullWidth
                                                    helperText="Enter an email subject - dynamic text supported"
                                                    // defaultValue={formData? formData.name: ""} // not showing up when use existing template is picked after Electronic Mail box
                                                    validate={[required()]}
                                                    toolbar={<CustomRichTextToolbar richTextMode={false} />}
                                                />
                                            </Grid> : null}
                                            <Grid item xs={12}>
                                                <RichTextInput
                                                    source='custom_message'
                                                    fullWidth
                                                    validate={[required()]}
                                                    toolbar={
                                                        <CustomRichTextToolbar
                                                            richTextMode={formData.channels.includes('email')}
                                                        />
                                                    }
                                                />
                                                {formData.channels.includes('sms') ? <FormDataConsumer>
                                                    {({ formData }) => {
                                                       let {smsDisplayField, hasDynamicText, messageLength} = creditProcessing(formData, specialTextNotify, setSpecialTextNotify, estimatedCredits, setEstimatedCredits, estimatedCreditsRange, setEstimatedCreditsRange, templateContent)

                                                        return (
                                                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                <div style={{ display: "flex", alignItems: "center" }}>
                                                                    {smsDisplayField}
                                                                    {hasDynamicText && (
                                                                        <HtmlTooltip style={{ marginLeft: "5px" }}
                                                                            title={
                                                                                <>
                                                                                    <Typography color="black">
                                                                                        We are unable to calculate the exact number of credits because you are using Dynamic Text. Dynamic text can create different lengths for each message. Therefore, credit usage can vary.
                                                                                    </Typography>
                                                                                </>
                                                                            }
                                                                        >
                                                                            <InfoOutlined fontSize='small' />
                                                                        </HtmlTooltip>
                                                                    )}
                                                                </div>
                                                                {specialTextNotify ? <ConfirmSpecialText formField={"custom_message"}/> : null}
                                                                {messageLength > 0 ? (
                                                                    <div style={{ display: 'inline-block' }}>
                                                                        <CustomClearText formField={"custom_message"} setNotGSMCompliantOnLoad={undefined}  />
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        )}}
                                                </FormDataConsumer > : null}
                                            </Grid>
                                        </>
                                    )}
                                </>
                            )}
                        }
                    </FormDataConsumer>
                </Grid>
            </SimpleForm>
        </Create>
    );
};

export default MessageBroadcastCreate;

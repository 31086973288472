import { useEffect } from "react";
import { Typography } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import { isGSM7Compliant } from "./gsm7ComplianceHelpers";
import { HtmlTooltip } from "./HtmlTooltip";
import { CustomClearText } from "./CustomClearText";
import { ConfirmSpecialText } from "./ConfirmSpecialText";
import { dynamicTextRanges } from "../../segments/dynamicTextRanges";

export const TemplateEstimatesField = ({ formData, setEstimatedCredits, setSpecialTextNotify, setEstimatedCreditsRange, setIsMultiCredit, estimatedCredits, estimatedCreditsRange, specialTextNotify, notGSMCompliantOnLoad, setNotGSMCompliantOnLoad }) => {

    useEffect(() => {

        // this is to handle the special text notification on load this should 
        // only effect the edit modal as this component wont have this prop
        if (notGSMCompliantOnLoad) {
            setSpecialTextNotify(true);
        }
    }, []);

    const smsMessage = formData.sms_message;
    let gsm7compliant = true;
    if (smsMessage) {
        gsm7compliant = isGSM7Compliant(smsMessage.replace(/^<p>|<\/p>$/gm, '')) // only remove p tags at the beginning and end
    }

    const htmlDecode = (input) => {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return doc.documentElement.textContent;
    }

    // check and handle message length

    // get the dynamic text keys
    const dynamicTextKeys = Object.keys(dynamicTextRanges);

    const dynamicTextUsage = dynamicTextKeys.reduce((acc, option) => {
        const count = (smsMessage?.match(new RegExp(option, 'g')) || []).length;
        if (count > 0) {
            acc[option] = count;
        }
        return acc;
    }, {});


    // console.log(dynamicTextUsage);

    let estimationLengthMin = 0;
    let estimationLengthMax = 0;
    // let estimationLengthAvg = 0;

    Object.keys(dynamicTextUsage).forEach(key => {
        const count = dynamicTextUsage[key];
        const minLength = dynamicTextRanges[key]?.min || 0;
        const maxLength = dynamicTextRanges[key]?.max || 0;
        // const avgLength = dynamicTextRanges[key]?.average || 0;
        estimationLengthMin += count * minLength;
        estimationLengthMax += count * maxLength;
        // estimationLengthAvg += count * avgLength;

    });

    // console.log(`Min Length: ${estimationLengthMin}, Max Length: ${estimationLengthMax}`);
    let parsedMessage = smsMessage?.replace(/<p>/gm, '') // removes html tags
    parsedMessage = parsedMessage?.replace(/<\/p>/gm, '\n') // removes html tags
    const messageLength = smsMessage?.length > 0 ? (Math.abs(Array.from(htmlDecode(parsedMessage)!).length - 1)) : 0;
    const newEstimatedCredits = Math.ceil(messageLength / (gsm7compliant ? 160 : 70));
    if (newEstimatedCredits !== estimatedCredits) {
        setEstimatedCredits(newEstimatedCredits);
    }
    // check and handle dynamic text
    let hasDynamicText = false
    hasDynamicText = dynamicTextKeys.some(option => smsMessage?.includes(option));
    if (!gsm7compliant && !specialTextNotify) {
        setSpecialTextNotify(true);
    } else if (gsm7compliant && specialTextNotify) {
        setSpecialTextNotify(false);
        // reset the flag so we can notify user again if they add special text after making basic
        if (setNotGSMCompliantOnLoad){ // this state hook is only available in the edit modal
            setNotGSMCompliantOnLoad(false);
        }
    }

    let warningStyle = { color: ((estimatedCredits > 1 || estimatedCreditsRange.length > 1 || (parseInt(estimatedCreditsRange) > 1))) ? "red" : "inherit" }
    // console.log("estimatedCredits", estimatedCredits, "estimatedCreditsRange", estimatedCreditsRange)

    const dynamicTextRegex = new RegExp(dynamicTextKeys.join('|'), 'g');
    const displayMin = parsedMessage?.length ? (Math.abs(Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - 1) + estimationLengthMin) : 0;
    const displayMax = parsedMessage?.length ? (Math.abs(Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - 1) + estimationLengthMax) : 0;
    // const displayAvg = parsedMessage?.length ? (Math.abs(Array.from(htmlDecode(parsedMessage)!.replace(dynamicTextRegex, '')).length - 1) + estimationLengthAvg) : 0;

    let minCalc;
    let maxCalc;
    if (hasDynamicText) {
        minCalc = Math.ceil(displayMin / (gsm7compliant ? 160 : 70))
        maxCalc = Math.ceil(displayMax / (gsm7compliant ? 160 : 70))
        // let avgCalc = Math.ceil(displayAvg / (gsm7compliant ? 160 : 70))
        if (minCalc !== maxCalc) {
            // console.log("minCalc", minCalc)
            // console.log("maxCalc", maxCalc)
            setEstimatedCreditsRange(`${minCalc}-${maxCalc}`);
            setIsMultiCredit(true);

        } else {
            // console.log("minCalc", maxCalc)
            setEstimatedCreditsRange(`${maxCalc}`);
            setIsMultiCredit(true);

        }

    }

    if (!hasDynamicText) {
        setEstimatedCreditsRange("");
    }


    // current twillio minimum is 1 credit per message if its more than that on calculation we need to update
    const multiCredit = newEstimatedCredits > 1;
    if (multiCredit) {
        setEstimatedCredits(newEstimatedCredits);
        setIsMultiCredit(true);
    } else {
        // if not already multicredit from above
        if (minCalc > 1 || maxCalc > 1) {
            setIsMultiCredit(true);
        } else {
            setIsMultiCredit(false);
        }
    }


    let smsDisplayField = <span style={{ fontSize: "14px", ...warningStyle }}>{`${parsedMessage?.length
        ? (hasDynamicText ?
            `${displayMin}-${displayMax}`
            : Math.abs(Array.from(htmlDecode(parsedMessage)!).length - 1))
        : 0} Characters ${hasDynamicText ? "Estimated" : ""} ${gsm7compliant ? "(Basic Text Mode)" : "(Special Character Mode)"} | ${hasDynamicText ? estimatedCreditsRange : estimatedCredits} ${estimatedCredits === 1 && ((estimatedCreditsRange.length === 1 && parseInt(estimatedCreditsRange) <= 1) || estimatedCreditsRange === "") ? "credit" : "credits"} per message ${hasDynamicText ? "estimated " : ""}`}</span>
    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
                {smsDisplayField}
                {hasDynamicText && <HtmlTooltip style={{ marginLeft: "5px" }}
                    title={
                        <Typography color="black">
                            We are unable to calculate the exact number of credits because you are using Dynamic Text.  Dynamic text can create different lengths for each message.  Therefore, credit usage can vary.
                        </Typography>
                    }
                >
                    <InfoOutlined fontSize='small' />
                </HtmlTooltip>}
            </div>
            {messageLength > 0 ? <div style={{ display: 'inline-block' }}><CustomClearText formField={"sms_message"} setNotGSMCompliantOnLoad={setNotGSMCompliantOnLoad} /></div> : null}
            {/* this should avoid showing the confirm special text modal on load for applicable edit templates but show appropriately in other templates */}
            {specialTextNotify && !notGSMCompliantOnLoad ? <ConfirmSpecialText formField={"sms_message"} /> : null}
        </div>
    )
}
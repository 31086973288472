import { AppBar, Logout, UserMenu, fetchUtils } from 'react-admin';
import {
    MenuItem,
    useMediaQuery,
    Theme,
    Select,
    IconButton,
    Typography
} from '@mui/material';

import { InfoOutlined } from '@mui/icons-material';


import Logo from './Logo';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useEffect, useMemo, useRef, useState } from 'react';
import {useLocation} from 'react-router-dom';
import tokenManager from '../tokenManager';
import { HtmlTooltip } from '../components/smstransparency/HtmlTooltip';

import '../scss/AppBar.scss';


const CustomUserMenu = () => {
    return (
      <UserMenu>
          <Logout />
      </UserMenu>
)};

const ProviderMenu = (props: any) => {

  // console.log("props", props)

  const location = useLocation();

  const urlParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const [selectedPractice, setSelectedPractice] = useState(() => urlParams.get("practice_id"))

  useEffect(() => {
    const practice_id = urlParams.get("practice_id")
    if (practice_id && practice_id !== selectedPractice) {
      setSelectedPractice(practice_id)
    }
  }, [urlParams, selectedPractice])

  useEffect(() => {
    if (selectedPractice !== null && selectedPractice !== sessionStorage.getItem("selectedPractice")) {
      props.handlePracticeChangeParams(selectedPractice);
    }
  }, [selectedPractice]);




    return (
        <div id="provider-selector">
            <Select
                labelId="user-providers"
                value={ selectedPractice? selectedPractice : props.currpractice}
                label="Practice"
                onChange={props.handlepracticechange}
                sx={{ borderColor: 'red' }}
            >
                {
                    props.practices.map((practice, i) => {
                        if(typeof practice['name'] === 'string' && practice['status'] === 'active') return (<MenuItem key={i} value={practice['id']}>{practice['name']}</MenuItem>) 
                    })
                }
            </Select>
        </div>
    )
}

const CustomAppBar = (props: any) => {

    const [isSupportMenuOpen, setIsSupportMenuOpen] = useState(false)
    const [currentPageState, setCurrentPageState] = useState("");
    const [intervalState, setIntervalState] = useState(null);
    const toggleSupportMenu = () => setIsSupportMenuOpen(!isSupportMenuOpen)

    function useOutsideAlerter(ref) {
      useEffect(() => {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setIsSupportMenuOpen(false)
          }
        }
    
        document.addEventListener("mousedown", handleClickOutside)
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside)
        }
      }, [ref])
    }

    useEffect(() => {
      const handleLocationChange = () => {
        setCurrentPageState(window.location.href);
      };

      window.addEventListener('popstate', handleLocationChange);

      return () => {
        window.removeEventListener('popstate', handleLocationChange);
      };
    }, []);

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef)


    const fetchCreditUsage = async (practiceId: string) => {
  
      const apiUrl = (window.location.hostname === "localhost") ? 'http://localhost:1337' : '';
      const accessToken = tokenManager.getToken();

      if (!practiceId) {
          console.error("Practice ID is missing");
          return;
      }

      const options: any = {}
      options['credentials'] = 'include'
      options['user'] = {
          authenticated: true,
          token: `Bearer ${accessToken}`,
        }

    
      const url = `${apiUrl}/kaizenovate/provider/1.0.0/get-sms-credit-usage?practice_id=${practiceId}`;

      const result = await fetchUtils.fetchJson(url, options)
      // console.log(result);

      
      if (result.status < 200 || result.status >= 300) {
        console.error("An error occurred while trying to sync patient DX codes")
      }
      
      return result.json;
      

  }

    const isLargeEnough = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('sm')
    );

    const SupportButton = () => {
      return (<IconButton className='support-menu-toggle' color="inherit" onClick={toggleSupportMenu}>
          <HelpOutlineOutlinedIcon />
      </IconButton>)
    };

  const SMSCreditsCalc = () => {
    const [creditsUsed, setCreditsUsed] = useState<number | null>(null);
    const [creditLimit, setCreditLimit] = useState<number | null>(null);

    useEffect(() => {
      // function to fetch credits
      const fetchCredits = async () => {
        // console.log("Fetching credits");
        const selectedPractice = sessionStorage.getItem("selectedPractice");
        if (selectedPractice) {
          const { credits_used, credit_limit } = await fetchCreditUsage(selectedPractice);
          setCreditsUsed(credits_used);
          setCreditLimit(credit_limit);
          // utilize local storage to store previous credits used and credit limit in case response times from server are slow
          sessionStorage.setItem('prevCreditsUsed', credits_used);
          sessionStorage.setItem('prevCreditLimit', credit_limit);
        } else {
          console.error("Selected practice is not set in sessionStorage");
        }
      };

      // function to set current page to state
      let interval;

      // if the current page is messagebroadcasts, messagetemplates, messageautomations, messagelogs, set interval to fetch credits every 15 seconds
      if ((window.location.href.includes("messagebroadcasts") || window.location.href.includes("messagetemplates") || window.location.href.includes("messageautomations") || window.location.href.includes("messagelogs"))) {
        if (!intervalState && !interval) {
          // console.log("Setting interval and starting fetches every 15 seconds");
          interval = setInterval(fetchCredits, 15 * 1000);
          setIntervalState(interval);
          props.setGlobalIntervalArray([...props.globalIntervalArray, interval])
        }
        fetchCredits();
      } else {
        if (interval) {
          // console.log("Clearing interval because you are leaving a connect page");
          clearInterval(interval);
        }
        if (intervalState) {
          // console.log("Clearing interval because you are leaving a connect page");
          clearInterval(intervalState);
          setIntervalState(null);
        }
        // console.log("Fetching credits 1 time");
        fetchCredits();
      }

      // clear interval on unmount
      return () => {
        // console.log("component is unmounting");
        if (!(window.location.href.includes("messagebroadcasts") || window.location.href.includes("messagetemplates") || window.location.href.includes("messageautomations") || window.location.href.includes("messagelogs"))) {
          // console.log("Clearing interval because you are leaving a connect page");
          // console.log("interval", interval);
          // console.log("intervalState", intervalState);
          // console.log("clearing global interval array", props.globalIntervalArray);
          props.globalIntervalArray?.forEach((interval) => {
            // console.log("clearing interval", interval);
            clearInterval(interval);
          });
          if (interval) {
            clearInterval(interval);
          }
          if (intervalState) {
            clearInterval(intervalState);
            setIntervalState(null);
          }
        }
        else {
          // console.log("on a connect page, not clearing interval");
        }
      };
    }, []);

    useEffect(() => {
      const prevCreditsUsed = sessionStorage.getItem('prevCreditsUsed');
      const prevCreditLimit = sessionStorage.getItem('prevCreditLimit');

      // if creditsUsed and creditLimit are null, but prevCreditsUsed and prevCreditLimit are not null, set creditsUsed and creditLimit to the previous values
      if (creditsUsed === null && creditLimit === null && prevCreditsUsed && prevCreditLimit) {
        setCreditsUsed(Number(prevCreditsUsed));
        setCreditLimit(Number(prevCreditLimit));
      }
    }, []);


    if (creditsUsed === null || creditLimit === null) {
      return <span>-- / -- Monthly SMS Credits Used</span>;
    }

    const creditsLeftDisplay = `${creditsUsed} / ${creditLimit} Monthly SMS Credits Used`;

    return <span style={{ color: creditsUsed >= creditLimit ? 'red' : 'inherit', display: "flex" }}>{creditsLeftDisplay}  <HtmlTooltip style={{ margin: "auto 0 auto 5px" }}
      title={
        < >

          <Typography color="black" style={{ padding: '10px 10px 0px 10px', textAlign: 'center' }}>
            Have questions?
          </Typography>
          <Typography color="black" style={{ padding: '10px' }}>
            <a href='https://kaizenovate.zendesk.com/hc/en-us' target='_blank'>Click here for our support article.</a>
          </Typography>


        </>
      }
    >
      <InfoOutlined fontSize='small' />
    </HtmlTooltip>
    </span>;
  }

    return (
      <div className='app-bar-container'>
        <AppBar
          {...props}
          className='app-bar'
          color="default"
          elevation={1}
          userMenu={<CustomUserMenu />}
        >
          {isLargeEnough && <Logo />}
          <div>&nbsp;{process.env.REACT_APP_RELEASE} <span>{process.env.REACT_APP_ENV}</span></div>
            <div style={{ margin: 'auto', flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
            <ProviderMenu practices={props.practices} currpractice={props.currpractice} handlepracticechange={props.handlepracticechange} handlePracticeChangeParams={props.handlePracticeChangeParams} />
            {(localStorage.getItem('isConnectUser') === 'true' && sessionStorage.getItem('embodiConnectEnabled') === 'true') ?
              <div style={{ paddingLeft: '10px' }}>
              <SMSCreditsCalc />
              </div>
              : null}
            </div>
          <SupportButton />
        </AppBar>
        {isSupportMenuOpen ? <div className='support-menu' ref={wrapperRef}>
          <div className='support-menu-item' onClick={toggleSupportMenu}>
            <a href="https://kaizenovate.zendesk.com/hc/en-us" target="_blank" className='support-menu-link'>
              Help Center
            </a>
          </div>
          <div className='support-menu-item' onClick={toggleSupportMenu}>
            <a href="https://kaizenovate.zendesk.com/hc/en-us/requests/new" target="_blank" className='support-menu-link'>
              Contact Support
            </a>
          </div>
        </div> : null}
      </div>
    );
};

export default CustomAppBar;
